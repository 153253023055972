
import FillButtonComponent from "../../../../shared/fillButtonComponent/FillButtonComponent";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import React, { useContext, useEffect, useState } from "react";
import AnsweredCountComponent from "../../../../shared/answeredCountComponent/AnsweredCountComponent";
import Context from "../../../../context/Context";

const QuizDragArrangeComponent = ({ question, questionCount, noOfQuestions, handleAnswerSubmit, leaderBoardOpen, selectedAnswer, sendTimeUp }) => {

    const { questionId, questionTitle, quizType, imageName, answerChoices, quizId } = question || {};

    const { host } = useContext(Context);

    const answerChoicesList = eval(answerChoices);

    const [state, setState] = useState([
        // {
        //     title: "index",
        //     items: ["1", "2", "3", "4"]

        // },
        {
            title: "Answer",
            items: []
        },
        {
            title: "Choices",
            items: [...answerChoicesList]
        }
    ]);

    // useEffect(() => {
    //     console.log(state);
    //     if (state) {
    //         // let data1 = Array.from(state);
    //         // console.log(data1);
    //         state.map((data, index) => {
    //             console.log(data, index);
    //         })
    //     }
    // }, [])

    const handleSubmit = () => {
        handleAnswerSubmit(state[0].items);
    }

    const handleDragEnd = ({ destination, source }) => {
        // console.log("to ", destination);
        // console.log("from ", source);
        if (!destination) {
            console.log("not valid drop location");
            return;
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            console.log("Dropped in same position");
            return;
        }

        const itemCopy = state.find(element => element.title === source.droppableId).items[source.index];
        // console.log(itemCopy);

        setState(prev => {
            // prev = {...prev};
            prev.find(element => element.title === source.droppableId).items.splice(source.index, 1);

            prev.find(element => element.title === destination.droppableId).items.splice(destination.index, 0, itemCopy);

            return prev;
        })
    }

    return (
        <div className={leaderBoardOpen? "quizDragArrange quizDragArrange__leaderboardOpen" : "quizDragArrange"}>
            {/* <div className="quizDragArrange__question">
                <p>{questionTitle}</p>

            </div> */}
            <div className="quizBasic__question">
                <p className="quizDragArrange__question">{questionTitle}</p>
                <p>
                    {questionCount} / {noOfQuestions}
                </p>
            </div>
            <div className="quizDragArrange__container">
                {host && question?.hostModeFlag && (
                    <div className="quizDragArrange__container__answerCount">
                        <AnsweredCountComponent />
                    </div>
                )}
                <div className="quizDragArrange__container__board" style={{pointerEvents: !!selectedAnswer?"none":"auto"}}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {state.map((data, index) => {
                            return (
                                <div key={index} className="quizDragArrange__board_column">
                                    <h3>{data.title}</h3>
                                    <Droppable droppableId={data.title}>
                                        {(provided) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    className={`quizDragArrange__droppable_column
                                                        ${data.title == "Answer" ?
                                                           ( !data.items.length ?
                                                                 "quizDragArrange__droppable_column_message": "") : ""}
                                                    `}
                                                >
                                                    {
                                                        data.title == "Answer" ?
                                                           ( !data.items.length ?
                                                                <h1>Drag and Drop here</h1> : "") : ""
                                                    }

                                                    {data.items.map((el, index) => {
                                                        return (
                                                            <Draggable key={el} index={index} draggableId={el}>
                                                                {(provided) => {
                                                                    return (
                                                                        <div className="quizDragArrange__item_wrapper">
                                                                            <span>{data.title == "Answer" ? index + 1 : ""}</span>
                                                                            <div className={data.title == "Answer" ?
                                                                                (el == "" ? "quizDragArrange__item_empty" :
                                                                                    "quizDragArrange__item quizDragArrange__item_answer") :
                                                                                "quizDragArrange__item"}
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                {el}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )

                                        }}
                                    </Droppable>
                                </div>
                            )
                        })}
                        {/* <div className="quizDragArrange__answer quizDragArrange__window">Answer</div>
                        <div className="quizDragArrange__options quizDragArrange__window">
                            Options

                            {answerChoicesList.map((choice, index) => (
                                <div className="quizDragArrange__chouce">
                                    {choice}
                                </div>
                            ))}
                        </div> */}
                    </DragDropContext>
                </div>
                {host && question?.hostModeFlag && (
                    <div className="quizDragArrange__container__skipBtn">
                      <FillButtonComponent 
                        displayText={"Skip"}
                        handleClick={sendTimeUp}
                        style={{ padding: "0.5rem 1rem" }}
                      />
                    </div>
                )}
            </div>
            {(!host || !question?.hostModeFlag) && (
                <div className="quizDragArrange__submitButton">
                    <FillButtonComponent displayText={"Submit"} handleClick={handleSubmit} isDisabled={!!selectedAnswer} />
                </div>
            )}
            {/* <div className="quizDragArrange__submit">Submit</div> */}
        </div>
    );

}

export default QuizDragArrangeComponent;