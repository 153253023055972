import React, { useContext } from 'react'
import Context from '../../context/Context';

import "./AnsweredCountComponent.scss"

const AnsweredCountComponent = () => {

  const { playerAnswerCount, leaderboardData } = useContext(Context);

  return (
    <div className="AnsweredCountComponent">
        <div className="AnsweredCountComponent__liveData">
          {playerAnswerCount}/{leaderboardData?.noOfActivePlayers}
        </div>
        <div className="AnsweredCountComponent__text">Answered</div>
    </div>
  )
}

export default AnsweredCountComponent