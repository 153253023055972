import React, { useContext } from 'react'
import "./QuizMCQOptionsComponent.scss"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Context from '../../../context/Context'

const QuizMCQOptionsComponent = ({ answerChoicesList, handleSubmitAnswer, selectedAnswer, hostModeFlag }) => {
    const { host } = useContext(Context);

    return (
        <Box sx={{ width: '100%' }} className="quizMCQOptions">
            <Grid container justifyContent="center" rowSpacing={{xs: 1, sm: 2, md: 3}} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
            {         
                answerChoicesList?.map((choice, index) => (
                <Grid item xs={12} sm ={12} md={6} lg={6} key={index}>
                    <button className="quizMCQOptions__button"
                    onClick = {handleSubmitAnswer}
                    disabled={!!selectedAnswer || (host && hostModeFlag)}  //disable submiting anymore answers when an answer is chosen
                    style = {{
                                backgroundColor : choice === selectedAnswer && "#FDB913", 
                                color : choice === selectedAnswer && "#30B2E7",
                                pointerEvents: !!selectedAnswer?"none":"auto",       //stop hover when an answer is selected    
                                fontFamily: choice === selectedAnswer && "CaustenB",      
                             }}
                    value={choice}
                    // id={index}
                    >
                    {`${String.fromCharCode(65+index)}.  ${choice}`}
                    </button>
                </Grid>
                ))
            }           
            </Grid>
        </Box>
    )
}  

export default QuizMCQOptionsComponent