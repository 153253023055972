import React from 'react'
import "./QtestQuestionInfoComponent.scss"
import FillButtonComponent from '../../../shared/fillButtonComponent/FillButtonComponent';

const QtestQuestionInfoComponent = ({handleNext, displayNote, endQuizFlag=false, infoDuration=5}) => {
  return (
    <>      
      {displayNote && (<div className="quizanswer__info">
        <p className="quizanswer__infoText">{displayNote}</p>
      </div>)}
      <div className="quizComponent__nextBtnFooter">
        <FillButtonComponent displayText={(endQuizFlag ? "End Quiz" : "PROCEED")}
          handleClick={handleNext} duration={infoDuration} />
      </div>
    </>
  )
}

export default QtestQuestionInfoComponent