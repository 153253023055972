import React from "react";
import "./PodiumComponent.scss";
import { Fragment, useContext, useState, useEffect } from "react";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import Context from "./../../context/Context";
import LeaderboardComponent from "./../leaderboardComponent/LeaderboardComponent";
import { BEURL, FEURL } from "./../../api/BaseUrl";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import QuizNavBar from "../quizComponent/quizNavBarComponent/QuizNavBarComponent";
import LeaderboardList from "../leaderboardList/LeaderboardList";
import configData from "../../config.json";
import InviteModalWrapper from "../../shared/inviteModalComponent/InviteModalWrapper";
import { MdContentCopy } from "react-icons/md";
import axios from "axios";
import apiEndpoints from "../../api/EndPoints";
import DownloadAsCSVComponent from '../../shared/downloadAsCSVComponent/DownloadAsCSVComponent';

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: open ? 'calc(100vw - 380px)' : "100vw",
    ...(open && {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

function PodiumComponent() {
  const {
    leaderboardData,
    setQuestionData,
    socket,
    setAnswerData,
    setPodiumPage,
    leaderBoardOpen,
    setLeaderBoardOpen,
    mobileLeaderBoardOpen,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
    quizType,
    setQuizType,
    continueQuizzing,
    setNoOfCorrectAns,
    host,
    isLoggedIn,
    roomId
  } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [finishStatus, setfinishStatus] = useState(false);

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  useEffect(() => {
    if (!socket) {
      navigate("/");
    }
  })

  // Clipboard code
  var shareScoreClipboard = new window.ClipboardJS(".podium__copyScoreContainer", {
    text: function (trigger) {
      return `${configData.shareScoreText1} ${getScore()} ${configData.shareScoreText2_WA} ${FEURL}`;
    }
  });
  shareScoreClipboard.on('success', function (e) {
    setTimeout(() => {
      setAlert("info", "Copied score details to clipboard");
    }, 100)
  });

  useEffect(() => {
    setLeaderBoardOpen(!isTablet);
  }, [isTablet]);

  const styledButton = {
    padding: "1.5rem 3rem",
    color: "#fff",
    fontSize: isMobile ? "0.875rem" : "1.5625rem",
    lineHeight: isMobile ? "0.875rem" : "1.5625rem",
    fontFamily: isMobile ? "CaustenEB" : "CaustenR",
    fontWeight: isMobile || "700",
    letterSpacing: "0.5px",
    borderRadius: "10px",
    marginTop: "auto",
  };

  const goBackToWaitingRoom = () => {
    setQuestionData({});
    setAnswerData({});
    setPodiumPage(false)
    setNoOfCorrectAns(0);
    quizType == "Ready Made" ? navigate("/quizselection") : navigate("/waitingroom");
    setQuizType("");
    socket.send(
      JSON.stringify({
        "action": "goBackToWaitingRoom",
        "playerName": sessionStorage.getItem("name"),
        "playerId": sessionStorage.getItem("sessionId"),
        "playerAvatar": sessionStorage.getItem("avatar")
      })
    );
  };

  const getRank = () => {
    // function for getting  my current rank
    let myPlayer = leaderboardData?.playerStats?.filter(
      (player) => player.playerId === sessionStorage.getItem("sessionId")
    );
    return `Your rank is ${myPlayer[0]?.rank}`;
  };

  const getScore = () => {
    let myPlayer = leaderboardData?.playerStats?.filter(
      (player) => player.playerId === sessionStorage.getItem("sessionId")
    );
    return myPlayer[0]?.totalScore;
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true);
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return (e.returnValue =
      "Data will be lost if you leave the page, are you sure?");
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", onReloadButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", onReloadButtonEvent);
    };
  }, []);

  const handleBackToHome = () => {
    navigate("/");
  };

  //Assumption only if winning player reached the podium he has won the game
  useEffect(() => {
    const rank = getRank().slice(-2)
    if (rank === " 1" && isLoggedIn){
      axios.get(
        BEURL + apiEndpoints.updateUserStats,
        {
          params:{ action : "won"}
        }
      )    
    }

  }, [])
  

  
  continueQuizzing && !host && //show alert for all except host when new game starts 
    setAlert("info", `Host has started a new game! Click on ${quizType == "Ready Made" ? "KEEP QUIZZING" : "BACK TO WAITING ROOM"} to join them`)

  const handleExcelSummaryDownload = () => { 
    setLoadingDisplayFlag(true);
    axios({
      url: BEURL + apiEndpoints.getExcelSummary, //your url
      method: 'POST',
      data: {
        roomId: roomId
      },
      responseType: 'blob', // important
    }).then((response) => {	
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'quizSummary.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch((err) => {
      setAlert("error",err.response.data)
    }).finally(()=>{
      setLoadingDisplayFlag(false);
    });
  }
  
  return (
    <>
      { socket &&
        (<Main className={leaderBoardOpen ? "podium" : "podium podium__overflow"} open={leaderBoardOpen && !isTablet} >
        {isTablet && (
          <QuizNavBar pageName={"QUIZ"} />
        )}
        {(isTablet && mobileLeaderBoardOpen) || (
          <Fragment>
            {host && leaderboardData.hostModeFlag ?
              (<h1>Thank you for playing the Quiz!</h1>)
              :
              (<h1>
                Good going! <br />
                {leaderboardData?.playerStats?.length > 1 ? getRank() : `Your score is ${getScore()}`}
              </h1>)
            }
            <Button
              onClick={goBackToWaitingRoom}
              style={{ ...styledButton, backgroundColor: continueQuizzing? "#fdb913": "grey" }}
              disabled={!continueQuizzing}
            >
              {quizType == "Ready Made" ? "KEEP QUIZZING" : (leaderboardData?.hostModeFlag || leaderboardData?.playerStats?.length > 1) ? "BACK TO WAITING ROOM" : "PLAY ANOTHER ROUND"}
            </Button>
            {!continueQuizzing&&<p>Please wait for host to start a new game</p>}
            <div className="podium__actions">
              <div className="podium__iconText" onClick={() => window.open("https://form.typeform.com/to/eu1qQNpz")}>
                <img src="/images/feedbackicon.svg" />
                <p>FEEDBACK</p>
              </div>
              <div onClick={handleBackToHome} className="podium__iconText">
                <img src="/images/HomeIcon.png" />
                <p>BACK TO HOME</p>
              </div>
              {host && <div className="podium__iconText" onClick={handleExcelSummaryDownload}>
                <img src="/images/download_csv.png" />
                <p>QUIZ SUMMARY</p>
              </div>}
              <InviteModalWrapper />
              {/* {host && (leaderboardData?.playerStats?.length > 1) && <DownloadAsCSVComponent jsonData={jsonData} jsonHeader={jsonHeader} fileName={csvFileName}>
                <div className="podium__iconText">
                  <img src="/images/download_csv.png" />
                  <p>DOWNLOAD SCORE</p>
                </div>
              </DownloadAsCSVComponent>} */}
            </div>
            <div className="podium__footer">
              <p>
                SHARE YOUR SCORE
              </p>
              <div className="podium__footer-actions">
                <div className="podium__footer-left">
                  <div className="podium__shareContainer">
                    <FacebookShareButton
                      url={`${FEURL}`}
                      quote={`${configData.shareScoreText1} ${getScore()} ${configData.shareScoreText2_FB} ${FEURL}`}
                    >
                      <FacebookIcon
                        size={isMobile ? 40 : 48}
                        style={{ borderRadius: "10px" }}
                      />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={`${FEURL}`}
                      title={`${configData.shareScoreText1} ${getScore()} ${configData.shareScoreText2_WA}`}
                      separator={" "}
                    >
                      <WhatsappIcon
                        size={isMobile ? 40 : 48}
                        style={{ borderRadius: "10px" }}
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton url={`${FEURL}`} title={`${configData.shareScoreText1} ${getScore()} ${configData.shareScoreText2_TWTR}`}>
                      <TwitterIcon size={isMobile ? 40 : 48} style={{ borderRadius: "10px" }} />
                    </TwitterShareButton>
                    <div className="podium__copyScoreContainer">
                      <MdContentCopy className="podium__copyScoreBtn" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {isTablet && mobileLeaderBoardOpen &&
          <div className="podium__leaderboardContainer">
            <LeaderboardList />
          </div>
        }

      </Main>
      )}
      {socket && (isTablet || (
        <LeaderboardComponent />
      ))}
    </>
  );
}

export default PodiumComponent;
