import React, { useContext, useEffect, useState } from "react";
import "./quizDragArrangeComponent.scss"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import _ from "lodash";
import FillButtonComponent from "../../../../shared/fillButtonComponent/FillButtonComponent";
import Context from "../../../../context/Context";
import QuizDragArrangeComponent from "./QuizDragArrangeComponent";


//This wrapper has been added coz, due to some bug in react-beautiful-dnd library,
//the drag and drop stops working properly if we access useContext(context) in the same
//file.

const QuizDragArrangeCompWrapper = ({ question,
    selectedAnswer,
    setSelectedAnswer,
    questionCount,
    noOfQuestions,
    sendTimeUp
}) => {

    const { socket, roomId, leaderBoardOpen, mobileLeaderBoardOpen, timerProgress, host } = useContext(Context);
    const { questionId, questionTitle, quizType, imageName, answerChoices, quizId } = question || {};

    const handleAnswerSubmit = (finalAnswer) => {
        // console.log(finalAnswer);
        // console.log(finalAnswer.toString());
        // console.log(JSON.stringify(finalAnswer));
        setSelectedAnswer(finalAnswer);
        socket.send(
            JSON.stringify({
                action: "submitAnswer",
                playerId: sessionStorage.getItem("sessionId"), //take from local storage later; it's static now
                questionId: questionId,
                quizId: quizId,
                roomId: roomId, //roomId, // take from context
                timeTaken: timerProgress,
                questionType: "DND",
                playerAnswer: JSON.stringify(finalAnswer),
            })
        );
    }



    return (
        <QuizDragArrangeComponent question={question}
            // setSelectedAnswer={setSelectedAnswer}
            // selectedAnswer={selectedAnswer}
            questionCount={questionCount}
            noOfQuestions={noOfQuestions}
            handleAnswerSubmit={handleAnswerSubmit}
            leaderBoardOpen={leaderBoardOpen}
            selectedAnswer={selectedAnswer}
            sendTimeUp={sendTimeUp}
        />
    );
}

export default QuizDragArrangeCompWrapper;