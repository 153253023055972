import React from "react";
import "./QuizAnswerComponent.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { S3URL } from "./../../../api/BaseUrl";
import Context from "./../../../context/Context";
import QuizMediaComponent from "./../quizMediaComponent/quizMediaComponent/QuizMediaComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import QuizAnswerMTF from "./QuizAnswerMTF";
import QuizArrangeDragArrange from "./QuizAnswerDragArrange";

const QuizAnswerComponent = ({
  answer,
  selectedAnswer,
  hostModeFlag
}) => {
  const navigate = useNavigate();
  const { podiumPage, mobileLeaderBoardOpen, setNoOfCorrectAns, host } = useContext(Context);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { correctAnswer, imageName, answerContent, quizType, questionType } = answer || {};

  let title;
  let displayAnswer = "'";
  let same = false;
  // ***patch code to allow open ended MCQ answers ***
  // if correct answer is null say thank you for your response
  if (eval(correctAnswer)[0] === ""){
    console.log("in if");
    if (selectedAnswer){
      same = true
      title = `Thank you for your response!`
    }else{
      same = false
      title = `Uh-Oh. You didn't choose any option`
    }
    
  }
  //elif rest
  // *** the above code is a patch to remove the patch DELETE the above if block and change the below else if block into an if block ***
  else if ((questionType == "DND") || (questionType == "MTF")) {
    const correctAnswerList = eval(correctAnswer);

    if (questionType == "MTF") { //MTF
      if (JSON.stringify(correctAnswerList[1]) == JSON.stringify(selectedAnswer)) {
        same = true
      }
    } else { //DND
      if (JSON.stringify(correctAnswerList) == JSON.stringify(selectedAnswer)) {
        same = true
      }
    }
    if (host && hostModeFlag) {
      title = `The correct answer is`;
    } else {
      if (same) { //MTF and DND
        title = `Good Job! the Correct Answer is`;
      } else {
        title = `Uh-Oh. the Correct Answer is`
      }
    }

  } else { // The rest
    const answers = eval(correctAnswer).map(ans => ans.toLowerCase())
    displayAnswer = eval(correctAnswer)
    same = answers.includes(selectedAnswer.toLowerCase()) ? true : false
    if (host && hostModeFlag) {
      title = `The correct answer is '${displayAnswer}'`;
    } else {
      if (same) {
        title = `Good Job! '${displayAnswer}' is the Correct Answer!`;
      } else {
        title = `Uh-Oh. '${displayAnswer}' is the Correct Answer!`
      }
    }
  }


  useEffect(() => {
    if (same) {
      setNoOfCorrectAns(prev => prev + 1);
    }
  }, []);

  const goToPodium = () => {
    if (podiumPage) {
      navigate("/podium");
    }
  }

  useEffect(() => {
    goToPodium()
  }, [podiumPage]);

  useEffect(() => {
    document.getElementById("main").scrollTo(0, 0)
  }, [])

  return (
    <div className="quizanswer">
      {isMobile && mobileLeaderBoardOpen || (
        <>
          <div className="quizanswer__title">
            <p className="quizanswer__titleText">{title}</p>
          </div>

          {questionType != "MTF" && questionType != "DND" && imageName &&
            <div className="quizanswer__image" style={{ marginBottom: (answerContent == "None") ? "30px" : "0px" }}>
              <QuizMediaComponent
                mediaUrl={S3URL + (quizType == "Interest Based"? "generated/" : "readymade/") +imageName}
              />
            </div>}

          {questionType == "MTF" && <QuizAnswerMTF correctAnswer={correctAnswer} />}
          {questionType == "DND" && <QuizArrangeDragArrange correctAnswer={correctAnswer} />}

          {(answerContent != "None") && <div className={questionType == "CNT"? "quizanswer__info quizanswer__connect":"quizanswer__info" }>
            <p className="quizanswer__infoText">{answerContent}</p>
          </div>}
        </>
      )}
    </div>
  );
};

export default QuizAnswerComponent;
